import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    const date = new Date(value);

    // Опции для форматирования даты
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',    // Сокращенный день недели, например, СБ
      day: 'numeric',      // Число месяца
      month: 'long',       // Название месяца
      hour: '2-digit',     // Часы с ведущим нулем
      minute: '2-digit',   // Минуты с ведущим нулем
      timeZone: 'Europe/Moscow'  // Часовой пояс - Москва
    };

    // Форматируем дату в нужный формат
    const formattedDate = date.toLocaleDateString('ru-RU', options);

    // Преобразуем строку к нужному виду и возвращаем результат
    return formattedDate.replace(',', '').toUpperCase() + ' ПО МСК';
  }

}
